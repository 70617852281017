table {
  margin: auto;
  /* width: 80%; */
  /* margin: 10%; */
  border-color: white;
}

.ptr tr {
  border: 1px solid black;
  border-collapse: collapse;
}

.table thead th {
  border-bottom: none;
}

table {
  border: 1px solid #dddddd;
}

.h1 {
  margin-top: 5px;
}

.table td {
  border-top: none;
}

table {
  border: 1px solid #dddddd;
  border-collapse: collapse;
}

.table td {
  border-top: none;
}

th,
td {
  padding: 15px;
  text-align: center;
  border-collapse: collapse;
  border-color: white;
}

.table th {
  border-top: white;
}

.table thead th {
  border-bottom: none;
}

tr {
  border-color: white;
}

.table-responsive {
  overflow-x: inherit;
}

.newTable {
  border-collapse: collapse;
  border: thin black;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px 15px 15px 15px;
  width: 95%;
  margin: auto;
  overflow: hidden;
}

.newTable thead tr {
  background-color: #e99794;
  text-align: left;
  border-color: white;
}

h1 {
  font-weight: inherit;
  margin: 10px 0 10px 0px;
  margin-top: 25px;
  /* outline-style:solid ; */
  border-radius: 2px;
}

.newTable th,
td {
  padding: 12px 15px;
}

.demo {
  /* margin: 1em 0 0.5em 0; */
  margin: 15px;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  position: relative;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.4);
  font-size: 32px;
  line-height: 40px;
  color: #355681;
  /* text-transform: uppercase;
	border-bottom: 2px solid rgba(53,86,129, 0.3); */
}

h1.test2 {
  font-family: "Trebuchet MS", sans-serif;
  font-size: 3em;
  letter-spacing: -2px;
  border-bottom: 2px solid black;
  text-transform: uppercase;
  margin: 15px;
}

.inputs {
  margin: 5px;
  height: 32px;
}

.miniCard {
  border: 1px solid black;
  width: fit-content;
  margin: 20px auto;
}

.miniCardLarge {
  width: 710px;
}

.miniCardSmall {
  height: 130px;
  width: 210px;
}

.miniCardTitle {
  background-color: black;
  color: white;
  padding: 10px;
}

.miniCardTable,
.miniCardTable td,
.miniCardTable th {
  border: none;
  padding: 5px 0;
}

.ReportContainer {
  display: grid;
  grid-template-columns: auto auto;
}

.ReportContainerItems {
  text-align: center;
  margin: 0 auto;
}

.Clickable {
  color: #34aabc;
  cursor: pointer;
}
table {
  /* border: red 1px solid; */
  width: 90%;
}

td {
  vertical-align: middle;
  text-align: left;
}

th {
  vertical-align: middle;
  text-align: left;
}

.success {
  color: green;
}

.warning {
  color: orange;
}

.danger {
  color: red;
}

.container {
  display: grid;
}

.status-div {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 30px;
  /* padding-left: 16px; */
}

.mt-1 {
  margin-left: 20px;
}

.csv-container {
  /* border: red 1px solid; */
  display: flex;
  flex-wrap: unset;
  flex-direction: row;
  /* min-height: 200px; */
}

.upload-container {
  /* border: red 1px solid; */
  justify-content: space-between;
}

.number-analyser-section {
  border: grey 1px solid;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;

}

#phone_number {
  width: 50%;
}

.button-group-number-analyser {
  width: 50%;
  display: flex;
  flex-direction: row-reverse;
}

.button-group-number-analyser button {
  float: right;
  /* width: 25%; */
}

.FilterTable table, .FilterTable td, .FilterTable tr {
  border: 0px;
}

.FilterTable td {
  text-align: left;
}

.FilterTable {
  width: 60%;
  float: left;
  border: 0px;
}

.FilterLabel {
  margin-left: 10px;
}

.Green {
    color: #53b416;
  }
  
  .Grey {
    opacity: 0.5;
  }

.ModalPasswordCheckContainer {
    background-color: red;
    /* width: 400px; */
    /* min-width: 25vw; */
    margin: 20px auto;
    padding: 20px;
  }
  
  .ModalPasswordCheckContainer h1 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
  }
  
  .ModalPasswordCheckContentContainer {
    display: grid;
    grid-template-columns: 8% 92%;
    grid-template-rows: auto auto auto auto;
  }
  
  .ModalPasswordCheckContentContainer p {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 0;
  }

.PasswordCheckContainer {
    background-color: #f2f2f2;
    width: 400px;
    min-width: 25vw;
    margin: 20px auto;
    padding: 20px;
  }
  
  .PasswordCheckContainer h1 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: bold;
    letter-spacing: normal;
    text-align: left;
  }
  
  .PasswordCheckContentContainer {
    display: grid;
    grid-template-columns: 8% 92%;
    grid-template-rows: auto auto auto auto;
  }
  
  .PasswordCheckContentContainer p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 0;
  }
  
  .PasswordCheckContentContainer img {
    width: 22px
  }
  
  
  
.table1{
    /* font-family: arial, sans-serif; */
    border-collapse: collapse;
    width: 100%;    
    margin-top: 20px;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
    border-radius: 5px 5px 0 0; 
    
  }
  .table1 th{
    background-color: #e99794;  }
  .table2 th{
    background-color: #e99794;  }
  .table1 table{
 }

  /* tr {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    border-collapse: collapse;
}​ */

.table2{
    border-collapse: collapse;
    width: 70%;
    margin-left: 50px;

  }
.bigTable th{
  border: 1px solid #dddddd;

}
.bigTable td{
  border: 1px solid #dddddd;

}
.bigTable  tr:nth-child(even) {
  background-color: #f2f2f2;
}

  
  td {
    text-align: left;
    padding: 8px;
    margin-top: 20px;
  }
  th{
    font-weight: bolder;
  }  
  .row-margin{
    margin-top: 20px;
  }

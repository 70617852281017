/* .my-popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 300px;
  padding: 5px;
}

.my-popup-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].my-popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.my-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].my-popup-overlay {
  background: transparent;
} */

.Modal {
  border: 12px solid black;
  background-color: #ffffff;
  padding: 20px 30px;
  margin: 10px;
  max-width: 500px;
  min-height: 200px;
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 400px;
  padding: 5px;
}